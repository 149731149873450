import { config } from '../config';
const currentVersion = '1.0';

export function getNftContractAddress() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nft-collections`,
  };
}

export function getNfts({ address, chainId, contractId }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nfts/${chainId}/${contractId}/${address}`,
  };
}

export function transferNft({
  memberId,
  to,
  walletPassword,
  gasFee,
  tokenId,
  contractAddress,
  chainId,
}) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nfts/transfer`,
    data: {
      chainId,
      contractAddress,
      memberId,
      to,
      walletPassword,
      gasFee,
      tokenId,
    },
  };
}

export function batchTransferNfts({
  contractAddress,
  to,
  walletPassword,
  gasFee,
  tokenIds,
  chainId,
}) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nfts/transfer/batch`,
    data: {
      chainId,
      contractAddress,
      to,
      walletPassword,
      gasFee,
      tokenIds,
    },
  };
}

export function getNftGasFee({ contractAddress, chainId, tokenId, to }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nfts/transfer/gasfee`,
    data: {
      chainId,
      contractAddress,
      tokenId,
      to,
    },
  };
}

export function getNftBatchGasFee({ contractAddress, chainId, tokenIds, to }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nfts/transfer/batch/gasfee`,
    data: {
      chainId,
      contractAddress,
      tokenIds,
      to,
    },
  };
}

export function getExternalNfts({ address, chainId }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/nfts/external/${chainId}/${address}`,
  };
}

export function resendCouponCode({ chainId, contractAddress, tokenId }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/nfts/activate/coupon/resend`,
    data: {
      chainId,
      contractAddress,
      tokenId,
    },
  };
}

export function activateCouponNFT({ chainId, contractAddress, tokenId, walletPassword }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/nfts/activate/coupon`,
    data: {
      chainId,
      contractAddress,
      tokenId,
      walletPassword,
    },
  };
}

export function getActivateCouponGasFee({
  chainId,
  contractAddress,
  tokenId,
  walletPassword,
}) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/nfts/activate/gas-fee`,
    data: {
      chainId,
      contractAddress,
      tokenId,
      walletPassword,
    },
  };
}

export function checkCouponActive({ chainId, contractAddress, tokenId }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/nfts/activate/${chainId}/${contractAddress}/${tokenId}`,
  };
}

export function getBlockchainConfigurations() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/blockchain-configurations`,
  };
}
