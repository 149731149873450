import { Divider, Typography } from '@mui/material';
// Utils
import { toDollar } from '@/utils/index';
// Hooks
import { useCurrency } from '@/providers/CurrencyProvider';
// Styles & Icons
import { DollarSignSolid } from '@/icons/iconComponents';
import { colors } from '@/theme';
import styles from './dollarToCoin.module.css';

export default function DollarToCoin({ dollar, coin, small = false, row = false }) {
  const { currency, useCreditCard, allCurrencies, handleConvertUsdToCoin } =
    useCurrency();
  const currencyDetails = allCurrencies.find((curr) => curr.symbol === currency);
  const currencyIcon = currencyDetails?.icon ?? '';

  const coinPrice = currency === 'ETH' ? coin.toFixed(8) : handleConvertUsdToCoin(dollar);

  return (
    <div
      className={row ? styles.priceContainerRow : styles.priceContainer}
      style={{
        ['--iconMargin']: small ? '0' : '2px',
      }}
    >
      <div className={row ? styles.usdRow : styles.usd}>
        <div className={styles.iconContainerUSD}>
          <DollarSignSolid className={styles.icon} />
        </div>
        <div className={styles.usdAmount} data-core-qa="storeProductsProductUSDPrice">
          <Typography variant="body1">{toDollar(dollar)}</Typography>
        </div>
      </div>
      {useCreditCard ? null : (
        <>
          <Divider
            color={colors.surfaces.layerTwoBorder}
            orientation={row ? 'vertical' : 'horizontal'}
            flexItem
            variant={row ? 'middle' : 'fullWidth'}
          />
          <div className={row ? styles.ethRow : styles.eth}>
            <div className={styles.iconContainerETH}>
              <img src={currencyIcon} alt="coin symbol" className={styles.coinIcon} />
            </div>
            <div className={styles.ethAmount} data-core-qa="storeProductsProductETHPrice">
              <Typography variant="body1">{`${coinPrice} ${currency}`}</Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
