import { useReducer, useState } from 'react';
// Hooks
import { useAuth } from './useAuth';
// Resources
import {
  getNftContractAddress,
  getNfts,
  getNftGasFee,
  transferNft,
  batchTransferNfts,
  getNftBatchGasFee,
  getExternalNfts,
  getActivateCouponGasFee,
  activateCouponNFT,
  checkCouponActive,
  resendCouponCode,
  getBlockchainConfigurations,
} from '@/resources/nft-service.resource';
// Utils
import { initNftState } from './constants';

export const nftReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_NFTS':
      return {
        ...state,
        loading: false,
        nfts: action.payload,
      };
    case 'SET_EXTERNAL_NFTS':
      return {
        ...state,
        externalNfts: action.payload,
      };
    case 'SET_CONTRACT_IDS':
      return {
        ...state,
        contractIds: action.payload,
      };
    case 'SET_BLOCKCHAIN_CONFIGURATIONS':
      return {
        ...state,
        blockchainConfigurations: action.payload,
      };
    case 'SET_PENDING_NFTS':
      return {
        ...state,
        pendingNfts: action.payload.map((id) => `${id}`),
      };
    case 'SET_TRANSFER_SUCCESS':
      return {
        ...state,
        transferSuccess: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'RESET':
      return initNftState.nft;
    default:
      return state;
  }
};

const {
  init,
  nft: nftError,
  coupon: couponError,
  couponPasscode: couponPasscodeError,
  gasFeeError,
  resendEmailError,
} = initNftState.nft.error;

export function useNfts(chainId) {
  const { fetcher } = useAuth();
  const [state, dispatch] = useReducer(nftReducer, initNftState.nft);
  const [gasFee, setGasFee] = useState(initNftState.gas);
  const [activateGasFee, setActivateGasFee] = useState(initNftState.gas);
  const handleSetGasState = (key, value) => {
    setGasFee((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSetActivateGasState = (key, value) => {
    setActivateGasFee((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const resetGasFee = () => setGasFee(initNftState.gas);
  const resetActivateGasFee = () => setActivateGasFee(initNftState.gas);
  const clearActivateGasFeeError = () => handleSetActivateGasState('error', null);
  const setNftError = (error) =>
    error
      ? dispatch({ type: 'SET_ERROR', payload: error })
      : dispatch({ type: 'SET_ERROR', payload: nftError });
  const clearError = () => dispatch({ type: 'SET_ERROR', payload: init });

  const fetchContractAddress = () =>
    fetcher(getNftContractAddress())
      .then((data) => data)
      .catch(setNftError);

  const fetchBlockchainConfigurations = () =>
    fetcher(getBlockchainConfigurations())
      .then((data) => data)
      .catch(setNftError);

  const handleFetchNfts = async (contractIds = [], address, blockchainConfigurations) => {
    const pendingNfts = state.pendingNfts;

    const promises = contractIds.map(
      ({ contractAddress: contractId, chainId: contractChainId, internalName }) =>
        fetcher(getNfts({ address, chainId: contractChainId, contractId }))
          .then((data) => {
            const dataWithContractId = data?.map((item) => ({
              ...item,
              contractId,
              chainId: contractChainId,
              collection: internalName || '',
              status: pendingNfts.includes(item.id) ? 'Pending' : 'Active',
              blockExplorerUrl: blockchainConfigurations?.find(
                (blockItem) => blockItem.chainId === contractChainId
              )?.blockExplorerUrl,
            }));
            return dataWithContractId || [];
          })
          .catch((e) => {
            console.info({ e });
            setNftError();
            return [];
          })
    );
    const responses = await Promise.all(promises).then((data) => data);
    const nfts = responses?.reduce((acc, val) => [...acc, ...val], []) ?? [];
    return nfts;
  };

  const handleFetchExternalNfts = async (address, chainId, blockchainConfigurations) => {
    try {
      const nfts = await fetcher(getExternalNfts({ address, chainId }));
      const nftsWithContractId = nfts?.map((nft) => ({
        ...nft,
        contractId: nft.contractAddress,
        isExternal: true,
        chainId,
        blockExplorerUrl: blockchainConfigurations?.find(
          (item) => item.chainId === chainId
        )?.blockExplorerUrl,
      }));
      return nftsWithContractId || [];
    } catch (e) {
      console.info({ e });
      setNftError();
      return [];
    }
  };

  const fetchNfts = async (address, loading = false) => {
    if (loading) dispatch({ type: 'SET_LOADING', payload: true });
    const contractIds = await fetchContractAddress();
    const blockchainConfigurations = await fetchBlockchainConfigurations();
    const nfts = await handleFetchNfts(
      contractIds,
      address,
      blockchainConfigurations?.blockchainConfigurations
    );
    const externalNfts = await handleFetchExternalNfts(
      address,
      chainId,
      blockchainConfigurations?.blockchainConfigurations
    );
    const filteredExternalNfts = externalNfts.filter(
      (item) => !nfts.some((nft) => nft.id === item.id)
    );
    if (nfts?.length) {
      dispatch({ type: 'SET_NFTS', payload: nfts });
    }
    if (externalNfts?.length) {
      dispatch({ type: 'SET_EXTERNAL_NFTS', payload: filteredExternalNfts });
    }
    dispatch({ type: 'SET_CONTRACT_IDS', payload: contractIds });
    dispatch({
      type: 'SET_BLOCKCHAIN_CONFIGURATIONS',
      payload: blockchainConfigurations?.blockchainConfigurations,
    });
    dispatch({ type: 'SET_LOADING', payload: false });
  };

  // Transfer NFT
  const transfer = (data) =>
    fetcher(transferNft({ ...data, chainId: data?.chainId || chainId })).then(() => {
      dispatch({ type: 'SET_PENDING_NFTS', payload: [data.tokenId] });
      dispatch({ type: 'SET_TRANSFER_SUCCESS', payload: true });
    });

  // Batch Transfer NFT
  const batchTransfer = (data) =>
    fetcher(batchTransferNfts({ ...data, chainId: data?.chainId || chainId })).then(
      () => {
        dispatch({ type: 'SET_PENDING_NFTS', payload: data.tokenIds });
        dispatch({ type: 'SET_TRANSFER_SUCCESS', payload: true });
      }
    );

  // Fetch transfer gas fee
  const fetchGasFee = (data) => {
    handleSetGasState('loading', true);
    return fetcher(getNftGasFee({ ...data, chainId: data?.chainId || chainId }))
      .then((val) => {
        handleSetGasState('value', val);
      })
      .catch(() => {
        handleSetGasState('error', gasFeeError);
      })
      .finally(() => handleSetGasState('loading', false));
  };

  const fetchBatchGasFee = (data) => {
    handleSetGasState('loading', true);
    return fetcher(getNftBatchGasFee({ ...data, chainId: data?.chainId || chainId }))
      .then((val) => {
        handleSetGasState('value', val);
      })
      .catch(() => {
        handleSetGasState('error', gasFeeError);
      })
      .finally(() => handleSetGasState('loading', false));
  };

  const fetchActivateGasFee = (data) => {
    handleSetActivateGasState('loading', true);
    return fetcher(
      getActivateCouponGasFee({ ...data, chainId: data?.chainId || chainId })
    )
      .then((val) => {
        handleSetActivateGasState('value', val);
      })
      .catch(() => {
        handleSetActivateGasState('error', gasFeeError);
      })
      .finally(() => handleSetActivateGasState('loading', false));
  };

  const activateNftCoupon = (data) => {
    return fetcher(activateCouponNFT({ ...data, chainId: data?.chainId || chainId }))
      .then(() => {
        clearError();
        resetActivateGasFee();
      })
      .catch((e) => {
        const error = e.response?.data;
        if (error?.ErrorCode === '70001') {
          setNftError(couponPasscodeError);
        } else {
          setNftError(couponError);
        }
        throw e;
      });
  };

  const checkNftCouponActive = (data) => {
    return fetcher(
      checkCouponActive({ ...data, chainId: data?.chainId || chainId })
    ).catch(setNftError);
  };

  const resendNftCouponCode = (data) => {
    return fetcher(
      resendCouponCode({ ...data, chainId: data?.chainId || chainId })
    ).catch(() => setNftError(resendEmailError));
  };

  return {
    clearError,
    gasFee,
    activateGasFee,
    resetGasFee,
    resetActivateGasFee,
    fetchContractAddress,
    fetchGasFee,
    fetchNfts,
    transfer,
    dispatch,
    state,
    batchTransfer,
    fetchBatchGasFee,
    fetchActivateGasFee,
    activateNftCoupon,
    checkNftCouponActive,
    resendNftCouponCode,
    clearActivateGasFeeError,
  };
}
