import { useState, useEffect } from 'react';
import { styled, Tooltip, IconButton, Typography } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';

// styles
import { colors } from '@/theme';

const StyledToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: width ? `${width}px` : 'none',
    padding: '8px',
    backgroundColor: colors.grey[700],
  },
}));

const CopiedToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.grey[700],
    width: 'inherit',
  },
}));

const OnHoverToolTip = ({
  headerText,
  displayText,
  hoverText,
  width,
  enableCopy = false,
  iconColor,
}) => {
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 1700);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const handleCopy = () => {
    setCopied(true);
    setOpen(false);
  };

  const handleTouch = () => {
    setOpen(!open);
  };

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onTouchStart={handleTouch}
      onTouchEnd={handleTouch}
    >
      <StyledToolTip
        title={
          <>
            {headerText && (
              <Typography
                variant="h5"
                style={{ color: colors.common.white, paddingBottom: '15px' }}
              >
                {headerText}
              </Typography>
            )}
            {hoverText}
            {enableCopy && (
              <CopyToClipboard text={hoverText} onCopy={handleCopy}>
                <IconButton
                  size="small"
                  style={{ marginLeft: 8, color: 'white' }}
                  onTouchStart={(e) => e.stopPropagation()}
                  onTouchEnd={(e) => e.stopPropagation()}
                >
                  <FileCopyIcon fontSize="inherit" />
                </IconButton>
              </CopyToClipboard>
            )}
          </>
        }
        placement="top"
        arrow
        open={open}
        width={width}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {displayText ? (
            displayText
          ) : (
            <InfoOutlinedIcon fontSize={'small'} sx={{ color: iconColor }} />
          )}
        </div>
      </StyledToolTip>
      <CopiedToolTip
        title="Copied"
        placement="top"
        open={copied}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <div />
      </CopiedToolTip>
    </div>
  );
};

export default OnHoverToolTip;
