import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { blue, green, orange, red, grey } from '@mui/material/colors';
// Config
import { colors } from './colors';
import { typography } from './typography';

// ! This is the main theme file, where you can override the default MUI theme
// ! You can also create a new theme file and import them here
const theme = extendTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', severity: 'error' },
          style: {
            backgroundColor: red[50],
          },
        },
        {
          props: { variant: 'outlined', severity: 'info' },
          style: {
            backgroundColor: blue[50],
          },
        },
        {
          props: { variant: 'outlined', severity: 'warning' },
          style: {
            backgroundColor: orange[50],
          },
        },
        {
          props: { variant: 'outlined', severity: 'success' },
          style: {
            backgroundColor: green[50],
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          // borderRadius: '16px'
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'store' },
          style: {
            border: `1px solid ${colors.surfaces.layerTwoDividers}`,
            backgroundColor: colors.surfaces.layerTwo,
            color: colors.surfaces.layerThreeBorderDark,
            '&:hover': {
              border: `1px solid ${colors.surfaces.layerThreeBorder}`,
              backgroundColor: colors.surfaces.layerTwo,
              color: colors.surfaces.layerThreeBorderDark,
            },
            '&:active': {
              border: `1px solid ${grey[900]}`,
              backgroundColor: colors.surfaces.layerTwoBorder,
              color: colors.surfaces.layerThreeBorderDark,
            },
            '.selected': {
              backgroundColor: grey[100],
              color: colors.surfaces.layerThreeBorderDark,
            },
          },
        },
        {
          props: { variant: 'metaMask' },
          style: {
            color: grey[700],
            border: `1px solid ${grey[700]}`,
            backgroundColor: '#ffffff',
            '&:hover': {
              backgroundColor: '#f8dbc3',
              color: grey[700],
            },
            '&:active': {
              color: '#f6851b',
              backgroundColor: grey[900],
            },
          },
        },
        {
          props: { variant: 'metaMask', color: 'secondary' },
          style: {
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            border: 'none',
            color: grey[700],
            backgroundColor: '#f8dbc3',
            '&:hover': {
              backgroundColor: '#fbbb80',
            },
          },
        },
        {
          props: { variant: 'stripe' },
          style: {
            color: '#ffffff',
            backgroundColor: '#3581ea',
            '&:hover': {
              backgroundColor: '#1970e9',
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[300],
        },
      },
      variants: [
        {
          props: { variant: 'mediumGrey' },
          style: {
            borderColor: grey[400],
          },
        },
      ],
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          color: grey[500],
          '&.quantityField': {
            border: `1px solid ${grey[400]}`,
            borderRight: 'none',
            borderLeft: 'none',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)',
        },
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:focus': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '.75rem', // 12px
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.surfaces.layerTwo,
          padding: '24px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          width: '16px',
          height: '16px',
          border: `1px solid ${colors.surfaces.layerTwoBorder}`,
          '&:hover': {
            border: `1px solid #156280`,
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        arrow: true,
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        ...colors,
      },
    },
  },
  typography: {
    ...typography,
  },
});

export { colors, typography };

export default theme;
