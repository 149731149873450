export const initialErrorState = {
  error: false,
  header: '',
  message: '',
};

export const initialCheckoutState = {
  error: initialErrorState,
  loading: false,
  invoice: {},
  etherscan: {},
};

export const initialInvoiceState = {
  invoiceItems: [],
  invoiceTotals: {},
};

export const initialProgressBarSteps = [
  {
    label: 'Order',
    status: 'active',
  },
  {
    label: 'Terms',
    status: 'incomplete',
  },
  {
    label: 'Submit',
    status: 'incomplete',
  },
  {
    label: 'Confirm',
    status: 'incomplete',
  },
];

export const initialProcessingState = {
  isPending: false,
  endTime: 0,
};

export const walletExtensionConnectors = {
  metamask: 'MetaMask',
};

export const initError = {
  header: '',
  body: '',
};

const nftError = {
  header: 'There was an error loading some of your NFTs',
  body: 'Please try again later.',
};
const nftCouponError = {
  header: 'NFT Coupon Activation Error.',
  body: 'We were unable to activate your NFT coupon. Please try again later.',
};

const couponPasscodeError = {
  header: 'NFT Coupon Activation Error.',
  body: 'We were unable to activate your NFT coupon. Incorrect Passcode.',
};

const gasFeeError = {
  header: 'Error fetching gas fee.',
  body: 'Please try again later.',
};

const resendEmailError = {
  header: 'Error sending coupon code email.',
  body: 'Please try again later.',
};

export const initNftState = {
  nft: {
    nfts: [],
    externalNfts: [],
    contractIds: [],
    blockchainConfigurations: [],
    loading: true,
    error: {
      init: initError,
      nft: nftError,
      coupon: nftCouponError,
      couponPasscode: couponPasscodeError,
      gasFeeError,
      resendEmailError,
    },
    transferSuccess: false,
    pendingNfts: [],
  },
  gas: {
    value: 0,
    loading: false,
    error: initError,
  },
  error: initError,
};
