import {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useNfts } from '@/hooks/useNfts';
// Utils
import { handleGetWallet, handleGetChainInfo } from '@/utils/wallet';
import { brand } from '@/brand/brand';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
// Helpers
import {
  walletReducer,
  initialWalletState,
  initErrorState,
} from './helpers/walletHelpers';

export const WalletContext = createContext({});

export const useWalletContext = () => useContext(WalletContext);

const WalletProvider = ({ children }) => {
  const router = useRouter();
  const { featureFlags } = useFeatureFlags();
  const { fetcher, status, authStatuses } = useAuth();

  const [state, dispatch] = useReducer(walletReducer, initialWalletState);
  const [walletError, setWalletError] = useState(initErrorState);
  const [coreWalletError, setCoreWalletError] = useState(initErrorState);
  const [brandCoins, setBrandCoins] = useState(null);
  const [chainInfo, setChainInfo] = useState(null);

  const nft = useNfts(chainInfo?.chainId);

  const serverError = walletError.server || coreWalletError.server;
  const isWalletLoading =
    state.walletCoinState.loading || state.coreWalletCoinState.loading;

  const hasCoreWallet =
    state.coreWalletCoinState.hasWallet &&
    !state.coreWalletCoinState.loading &&
    !serverError;

  const hasNoWallet =
    !state.walletCoinState.hasWallet &&
    !hasCoreWallet &&
    !state.walletCoinState.loading &&
    !serverError;

  const shouldUseCore = hasCoreWallet || hasNoWallet || !featureFlags.LegacyWallets;

  // Find out if we are using the legacy wallet or core wallet
  const getIsCore = router.query?.core ? router.query?.core === 'true' : shouldUseCore;

  const userWalletId = hasCoreWallet
    ? state.coreWalletCoins[0]?.walletId
    : state.walletCoins[0]?.walletId;

  const handleSetError = (error) => {
    if (error === 'reset') {
      setWalletError(initErrorState);
      setCoreWalletError(initErrorState);
      return;
    }
    getIsCore
      ? setCoreWalletError((prev) => ({ ...prev, ...error }))
      : setWalletError((prev) => ({ ...prev, ...error }));
  };

  const findCoin = (symbol) => {
    const wallet = getIsCore ? state.coreWalletCoins : state.walletCoins;
    return wallet?.find((coin) => coin?.coinSymbol === symbol);
  };

  const getWalletItems = (isLoading) =>
    handleGetWallet(
      isLoading,
      true,
      fetcher,
      dispatch,
      findCoin,
      handleSetError,
      featureFlags.ShowBridge
    );

  // Wallet 2.0
  const coreGetWalletItems = (isLoading) =>
    handleGetWallet(
      isLoading,
      false,
      fetcher,
      dispatch,
      findCoin,
      handleSetError,
      featureFlags.ShowBridge
    );

  const handleGetActiveWallets = (setLoading = false) => {
    // Get Legacy Wallet Items if still active
    if (
      !state.hasDuplicateWallets &&
      state.walletCoinState.hasWallet &&
      featureFlags.LegacyWallets
    )
      getWalletItems(setLoading);
    // Get Core Wallet Items if active
    if (hasCoreWallet) coreGetWalletItems(setLoading);
  };

  const handleClearWallet = () => {
    dispatch({ type: 'CLEAR_WALLET' });
    setBrandCoins(null);
  };

  const chainIdIntervalRef = useRef(null);
  useEffect(() => {
    const fetchAndSetChainId = async () => {
      if (status !== authStatuses.SIGNED_IN) {
        if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
        return;
      }

      const attemptFetch = async () => {
        const chainInfo = await handleGetChainInfo(fetcher);
        if (chainInfo) {
          setChainInfo(chainInfo);
          if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
          return true;
        }
        return false;
      };

      const success = await attemptFetch();
      if (!success) {
        // If the first attempt fails, start retrying every 20 seconds
        chainIdIntervalRef.current = setInterval(attemptFetch, 20000);
      }
    };

    fetchAndSetChainId();

    return () => {
      if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
    };
  }, [status, authStatuses.SIGNED_IN]);

  // Init get user wallets
  useEffect(() => {
    // Clear wallet(s) on user logout
    if (status !== authStatuses.SIGNED_IN) {
      handleClearWallet();
      nft.dispatch({ type: 'RESET' });
      nft.resetGasFee();
      return;
    }

    featureFlags.LegacyWallets
      ? getWalletItems()
      : dispatch({ type: 'SET_WALLET_LOADING', payload: false });
    coreGetWalletItems();

    const updateWalletInterval = setInterval(handleGetActiveWallets, 60000);

    return () => clearInterval(updateWalletInterval);
  }, [status]);

  // Set brand coin
  useEffect(() => {
    const isCore = getIsCore;
    setBrandCoins({
      isCore,
      l1: findCoin(brand.coin, !isCore),
      l2: findCoin(`${brand.coin}-P`, !isCore),
    });
  }, [state.walletCoins, state.coreWalletCoins, router.query?.core]);

  return (
    <WalletContext.Provider
      value={{
        brandCoins,
        chainInfo,
        coreGetWalletItems,
        coreWalletError,
        findCoin,
        getIsCore,
        getWalletItems,
        handleClearWallet,
        handleGetActiveWallets,
        hasCoreWallet,
        hasNoWallet,
        isWalletLoading,
        nft,
        serverError,
        userWalletId,
        walletError,
        coreWalletCoins: state.coreWalletCoins,
        coreWalletCoinState: state.coreWalletCoinState,
        hasDuplicateWallets: state.hasDuplicateWallets,
        walletCoins: state.walletCoins,
        walletCoinState: state.walletCoinState,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
