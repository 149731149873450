const TEN_MINUTES_IN_MILLIS = 600000;

export function isBrowser() {
  return typeof window !== 'undefined';
}

/********************************************************************************
 * token refresh interval singleton only allows one instance to ever be created *
 *******************************************************************************/
export const setTokenRefreshInterval = (() => {
  let sessionInterval = null;

  return {
    // tokenRefreshRate needs to be less than 59 minutes
    getInstance({
      user,
      refreshTheToken,
      currentExpiration,
      tokenRefreshRate = TEN_MINUTES_IN_MILLIS,
    }) {
      // currentExpiration is in seconds. Convert to milliseconds and subtract the current time to get correct milliseconds before token expires
      const tokenTimeRemaining = currentExpiration * 1000 - Date.now();
      if (tokenTimeRemaining < tokenRefreshRate) {
        refreshTheToken({ user, method: 'GET' });
        console.info(
          `token has been refreshed due to the current expiration time ${currentExpiration} is before the scheduled refresh`
        );
      }
      if (sessionInterval) return sessionInterval;
      else {
        sessionInterval = setInterval(() => {
          refreshTheToken({ user, method: 'GET' });
        }, tokenRefreshRate);
        console.info(
          `token refresh interval ID:${sessionInterval} is every ${tokenRefreshRate} milliseconds`
        );
        return sessionInterval;
      }
    },
  };
})();

export const capitalize = (str) => !!str && str[0].toUpperCase() + str.slice(1);

export function getFeatureFlag({ name }) {
  const flag = localStorage.getItem(name);
  return flag === 'true';
}

export const formatAmount = (amount, digits) => {
  amount = Number(amount).toFixed(digits).toString().split('.');
  amount[0] = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (amount = amount.join('.'));
};

export const toDollar = (amount) =>
  Number(amount).toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
  });

export const handleCopyClick = (copyText, setIsCopied) =>
  navigator.clipboard
    .writeText(copyText)
    .then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1600);
    })
    .catch((error) => {
      console.warn(error);
    });

export const formatDate = (date, size = 'long', withTime) => {
  const timestamp = new Date(date);
  return withTime
    ? timestamp.toLocaleString('en-US', {
        dateStyle: size,
        timeStyle: size,
      })
    : timestamp.toLocaleString('en-US', {
        year: 'numeric',
        month: size,
        day: 'numeric',
      });
};

export const isDigitalReward = (coin) => coin?.coinSymbol.includes('-P');

export function cssValue(property) {
  return getComputedStyle(document.documentElement).getPropertyValue(property);
}

export function formatHODLUSD(value) {
  const convertedValue = Number(value);
  if (isNaN(convertedValue)) {
    return 'Invalid Input';
  }

  const absValue = Math.abs(convertedValue);
  const suffix = absValue >= 1e6 ? 'M' : absValue >= 1e3 ? 'k' : '';

  const divisor = suffix === 'M' ? 1e6 : suffix === 'k' ? 1e3 : 1;
  const formattedValue = (absValue / divisor).toFixed(0) + suffix;
  return formattedValue;
}

export function formatWalletAddress(address, start = 5, end = 4) {
  if (typeof address === 'string' && address.length > start + end) {
    return `${address.substring(0, start)}...${address.substring(address.length - end)}`;
  }
  return address;
}

export const formatPathName = (path) =>
  path === '/'
    ? 'Login'
    : path
        .split('/')
        .slice(1)
        .map((path) => {
          // Remove manage, dashboard, and wallet from title
          if (
            path.includes('manage') ||
            path.includes('dashboard') ||
            path.includes('wallet')
          )
            return '';
          // Remove query params from title
          if (path.includes('?')) {
            return capitalize(path.split('?')[0]);
          }
          // Remove dashes from title
          if (path.includes('-')) {
            const splitPath = path.split('-');
            return splitPath.map(capitalize).join(' ');
          }
          if (path.includes('nft')) return 'NFTs';
          return capitalize(path);
        })
        .join(' ');
