import 'animate.css';
import { toast, cssTransition } from 'react-toastify';
import styled from '@emotion/styled';
// Styles
import { colors } from '@/theme';

const MainToast = styled.div`
  padding: 10px;
`;
const SuccessToast = styled(MainToast)`
  background-color: ${colors.success.main};
`;
const WarningToast = styled(MainToast)`
  background-color: ${colors.warning.main};
`;
const ErrorToast = styled(MainToast)`
  background-color: ${colors.error.main};
`;
const InfoToast = styled(MainToast)`
  background-color: ${colors.info.main};
`;

const fade = cssTransition({
  enter: 'animate__animated animate__fadeIn animate__faster',
  exit: 'animate__animated animate__fadeOut animate__faster',
});

const Toast = {
  success: (message) => {
    return toast.success(<SuccessToast>{message}</SuccessToast>, {
      transition: fade,
    });
  },
  warning: (message) => {
    return toast.warning(<WarningToast>{message}</WarningToast>, {
      transition: fade,
    });
  },
  error: (message) => {
    return toast.error(<ErrorToast>{message}</ErrorToast>, {
      transition: fade,
    });
  },
  info: (message) => {
    return toast.info(<InfoToast>{message}</InfoToast>, {
      transition: fade,
    });
  },
};

export default Toast;
